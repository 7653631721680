import { Link } from 'gatsby'
import React, { useState } from 'react'
import LogoImage from '../../images/logo/red-logo.svg'
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagramSquare } from "react-icons/fa";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Modal } from 'react-bootstrap';
import SuccessIcon from '../../images/icons/success.png'

const Footer = () => {

    const [email, setEmail] = useState('')
    const [showModal, setShowModal] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await addToMailchimp(email)
        if(result === 'success') {
            showModal(true)
        }
    }

    const handleChange = (e) => {
        const { value } = e.target;
        setEmail(value)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <div className="container mt-5">
            <div className="row mb-4">
                <div className="col-md-4 mb-md-0 mb-4">
                    <img src={LogoImage} alt="logo" style={{ maxWidth: '150px' }} className="mb-3" />
                    <p className="font-weight-bold small mb-1">Subscribe</p>

                    <p className="small mb-4">Select topics and stay current with our latest insights</p>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row mt-4 pt-1">
                            <div className="col-md-8 mb-md-0 mb-3 pr-md-0">
                                <input type="email" value={email} name="email" onChange={(e) => handleChange(e)} required className="form-control subscribe-form" placeholder="Email Address" />
                            </div>

                            <div className="col-md-4">
                                <button type="submit" className="btn btn-red btn-small h-100 btn-red-black px-4 no-border-radius">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-8 d-flex flex-column justify-content-end">
                    <div className="row">
                        <div className="col-md-4 mb-md-0 mb-3">
                            <p className="font-weight-bold small">Solutions</p>

                            <Link to="/digital"><p className="cursor small black-link-text mb-2">Digital</p></Link>

                            <Link to="/transformation"><p className="cursor small mb-2 black-link-text">Transformation</p></Link>

                            <Link to="/intelligent-automation"><p className="cursor small mb-2 black-link-text">Intelligent Automation</p></Link>

                            <Link to="/human-capital-development"><p className="cursor small mb-2 black-link-text">Human Capital Development</p></Link>

                            <Link to="/risk-management"><p className="cursor small mb-2 black-link-text">Risk management</p></Link>
                        </div>

                        <div className="col-md-4 mb-md-0 mb-3">
                            <p className="font-weight-bold small">Use cases</p>

                            <Link to="/digital-usecase"><p className="cursor small mb-2 black-link-text">Digital</p></Link>

                            <Link to="/transformation-usecase"><p className="cursor small mb-2 black-link-text">Transformation</p></Link>

                            <Link to="/human-capital-development-usecase"><p className="cursor small mb-2 black-link-text">Human Capital Development</p></Link>

                            <Link to="/intelligent-automation-usecase"><p className="cursor small mb-2 black-link-text">Intelligent Automation</p></Link>

                            <Link to="/security-usecase"><p className="cursor small mb-2 black-link-text">Risk Management</p></Link>

                        </div>


                        <div className="col-md-3 mb-md-0 mb-3">
                            <p className="font-weight-bold small">Reach Us</p>
                            <p className="cursor small mb-2">info@procentriciq.com</p>
                            <p className="cursor small mb-2">+234 809 990 8643</p>
                            

                            <Link to="/company-profile"><p className="text-red mb-0 pb-0 small cursor" style={{textDecoration: 'underline'}}>Get Company Profile</p></Link>

                            <div className="d-inline-flex mt-md-4 mt-4">
                                <div className="social-media-div mr-3 cursor">
                                    <FaFacebookF />
                                </div>

                                <div className="social-media-div mr-3 cursor">
                                    <FaTwitter />
                                </div>

                                <div className="social-media-div mr-3 cursor">
                                    <FaYoutube />
                                </div>

                                <div className="social-media-div cursor">
                                    <FaInstagramSquare />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div className="row mt-4">
                <div className="col-md-12 text-center">
                    <p className="text-secondary small">© 2011-2020 Procentric IQ</p>
                </div>
            </div>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header style={{ borderBottom: 'none' }} closeButton>
                </Modal.Header>
                <Modal.Body className="py-5 text-center">

                    <img className="text-center mx-auto mb-3" style={{maxWidth: '45px'}} src={SuccessIcon} alt="success" />
                    <h5 className="text-center mb-5">Thanks for subscribing</h5>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Footer
